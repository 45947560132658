import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout/index.amp'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from '../../config'
import AuthorDetails from "./authorDetails.amp";
import CategoryTitle from "../components/helpers/categoryTitle.amp";
import ArticlePreview from "./articlePreview.amp";

// The pages created in gatsby-node.js will use this component("template" by gatsby convention)
// The data is recieved by the graphQL query from the bottom of this component
const AuthorPage = props => {

    // SEO - description (Page, twitter, facebook)
    const profile = props.data.author.bio

    // SEO - title (Page, twitter, facebook)
    const title = props.data.author.name

    // SEO - url (Page, twitter, facebook)
    const url = `${metadata.url}/authors/${props.data.author.slug}`

    const seo = {
        title: `${metadata.defaultTitle} | ${title}`,
        description: profile,
        image: props.data.author.profilePhoto
    }

    return (
        <Layout>
            {/* SEO data for the html <head></head> */}
            <Basic seo={seo}/>
            <Twitter seo={seo} url={url}/>
            <Facebook seo={seo} url={url}/>

            <main className="wrapper" role="main">
                <article className={"w-full"}>
                    <AuthorDetails author={props.data.author}/>
                    <hr className={"mb-4"}/>
                    <CategoryTitle title="Latest Articles"/>
                    <div>
                        {props.data.allDatoCmsArticle.nodes.map((item, index) => {
                            return (<ArticlePreview key={index} data={item}
                                                    className={"border-b-default border-gray-400"}
                                                    imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:ml-0 lg:float-left mt-8 md:mt-0"
                                                    headingClass="font-serif leading-snug mb-1 md:mb-4 text-lg"
                                                    textClass={"line-clamp l-c-5 mb-2"}
                                                    imageFromUrl={true}
                                                    publishedAt={false}
                                                    imageMeta={{w: 320, ar: '4:3', fit: 'crop'}}
                                                    noReadMore={true}
                                                    publishedAtFormat={'mmmm d, yyyy'}
                                                    noAuthor={true}/>)
                        })}
                    </div>
                </article>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query AuthorQueryAMP($slug: String!) {
        author: datoCmsAuthor(slug: {eq: $slug}) {
            bio
            linkedin
            email
            profilePhoto {
                url,
                width,
                height
            }
            name
            slug
        }
        allDatoCmsArticle(filter: {author: {slug: {eq: $slug}}, website: {elemMatch: {name: {eq: "pt"}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                heading
                excerpt
                excerptNode {
                  childMarkdownRemark {
                    html
                  }
                }
                slug
                category {
                    title
                    slug
                    treeParent {
                        slug
                    }
                    position
                    website {
                        name
                    }
                }
                cover {
                    url
                }
                publishedAt
                content
            }
            totalCount
        }
    }
`
export default AuthorPage
